import abbeyPDF from "./pdf/abbey.pdf"
import abbeyExterior from "./img/abbey.jpg"
// import broadwayPDF from "./pdf/broadway.pdf"
// import broadwayExterior from "./img/broadway.jpg"
import chandlerPDF from "./pdf/chandler.pdf"
import chandlerExterior from "./img/chandler.jpg"
import copperleafPDF from "./pdf/copperleaf.pdf"
import copperleafExterior from "./img/copperleaf.jpg"
import foundryPDF from "./pdf/foundry.pdf"
import foundryExterior from "./img/foundry.jpg"
import horizonPDF from "./pdf/horizon.pdf"
import horizonExterior from "./img/horizon.jpg"
import ivyPDF from "./pdf/ivy.pdf"
import ivyExterior from "./img/ivy.jpg"
import lakesidePDF from "./pdf/lakeside.pdf"
import lakesideExterior from "./img/lakeside.jpg"
import melrosePDF from "./pdf/melrose.pdf"
import melroseExterior from "./img/melrose.jpg"
import moonstonePDF from "./pdf/moonstone.pdf"
import moonstoneExterior from "./img/moonstone.jpg"
import patinaPDF from "./pdf/patina.pdf"
import patinaExterior from "./img/patina.jpg"
import sundancePDF from "./pdf/sundance.pdf"
import sundanceExterior from "./img/sundance.jpg"

const routes = [
  {
    endpoint: "abbey-at-boulevard",
    title: (
      <span>
        Abbey <br />
        at Boulevard
      </span>
    ),
    subtitle: (
      <span>
        Townhomes <br />
        in Dublin, CA
      </span>
    ),
    sqft: "Approx. 2,192 – 2,430 Sq. Ft.",
    beds: "4",
    baths: "3.5",
    garage: "2-Bay",
    options: [],
    image: abbeyExterior,
    pdf: abbeyPDF,
    filename: "Abbey at Boulevard Brochure",
  },
  // {
  //   endpoint: "broadway-at-boulevard",
  //   title: <span>Broadway <br/>at Boulevard</span>,
  //   subtitle: <span>Townhome-Style Condos <br/>in Dublin, CA</span>,
  //   sqft: "Approx. 1,503 – 2,745 Sq. Ft.",
  //   beds: "3 - 4",
  //   baths: "3",
  //   garage: "1 – 2-Bay",
  //   options: [],
  //   image: broadwayExterior,
  //   pdf: broadwayPDF,
  //   filename: 'Broadway at Boulevard Brochure'
  // },
  {
    endpoint: "chandler",
    title: <span>Chandler</span>,
    subtitle: (
      <span>
        Single-Family Homes <br />
        in Brentwood, CA
      </span>
    ),
    sqft: "Approx. 1,942 – 2,838 Sq. Ft.",
    beds: "4 – 5",
    baths: "2.5 – 4.5",
    garage: "2-Bay",
    options: [],
    image: chandlerExterior,
    pdf: chandlerPDF,
    filename: "Chandler Brochure",
  },
  {
    endpoint: "copperleaf-at-riversound",
    title: (
      <span>
        Copperleaf <br />
        at RiverSound
        <br />
      </span>
    ),
    subtitle: (
      <span>
        Townhomes <br />
        in Napa, CA
      </span>
    ),
    sqft: "Approx. 1,880 – 2,141 Sq. Ft.",
    beds: "3 – 4",
    baths: "3",
    garage: "2-Bay",
    options: [],
    image: copperleafExterior,
    pdf: copperleafPDF,
    filename: "Copperleaf at RiverSound Brochure",
  },
  {
    endpoint: "foundry-at-riversound",
    title: (
      <span>
        Foundry <br />
        at RiverSound
        <br />
      </span>
    ),
    subtitle: (
      <span>
        Single-Family Homes <br />
        in Napa, CA
      </span>
    ),
    sqft: "Approx. 2,138 – 2,661 Sq. Ft.",
    beds: "3 – 4",
    baths: "3.5",
    garage: "2-Bay",
    options: [],
    image: foundryExterior,
    pdf: foundryPDF,
    filename: "Foundry at RiverSound Brochure",
  },
  {
    endpoint: "horizon-at-onelake",
    title: (
      <span>
        Horizon <br />
        at One Lake
      </span>
    ),
    subtitle: (
      <span>
        Single-Family Attached Homes <br />
        in Fairfield, CA
      </span>
    ),
    sqft: "Approx. 2,048 – 2,290  Sq. Ft.",
    beds: "3",
    baths: "2.5",
    garage: "2-Bay",
    options: ["Optional Bedroom 4"],
    image: horizonExterior,
    pdf: horizonPDF,
    filename: "Horizon at One Lake Brochure",
  },
  {
    endpoint: "ivy-at-boulevard",
    title: (
      <span>
        Ivy <br />
        at Boulevard
      </span>
    ),
    subtitle: (
      <span>
        Single-Family Homes <br />
        in Dublin, CA
      </span>
    ),
    sqft: "Approx. 2,565 – 3,300 Sq. Ft.",
    beds: "4 – 6",
    baths: "3.5 – 5",
    garage: "2-Bay",
    options: [],
    image: ivyExterior,
    pdf: ivyPDF,
    filename: "Ivy at Boulevard Brochure",
  },
  {
    endpoint: "lakeside-at-onelake",
    title: (
      <span>
        Lakeside <br />
        at One Lake
      </span>
    ),
    subtitle: "Condos in Fairfield, CA",
    sqft: "Approx. 1,454 – 2,323 Sq. Ft.",
    beds: "2 – 3",
    baths: "2",
    garage: "2-Bay",
    options: [],
    image: lakesideExterior,
    pdf: lakesidePDF,
    filename: "Lakeside at One Lake Brochure",
  },
  {
    endpoint: "melrose-at-boulevard",
    title: (
      <span>
        Melrose <br />
        at Boulevard
      </span>
    ),
    subtitle: (
      <span>
        Single-Family Detached Homes <br />
        in Dublin, CA
      </span>
    ),
    sqft: "Approx. 2,094 – 3,049 Sq. Ft.",
    beds: "3 – 5",
    baths: "2.5 – 4",
    garage: "2-Bay",
    options: [],
    image: melroseExterior,
    pdf: melrosePDF,
    filename: "Melrose at Boulevard Brochure",
  },
  {
    endpoint: "moonstone-at-rosewood",
    title: (
      <span>
        Moonstone <br />
        at Rosewood
      </span>
    ),
    subtitle: (
      <span>
        Townhomes <br />
        in Morgan Hill, CA
      </span>
    ),
    sqft: "Approx. 1,844 – 2,085 Sq. Ft.",
    beds: "3 – 4",
    baths: "3.5",
    garage: "2-Bay",
    options: [],
    image: moonstoneExterior,
    pdf: moonstonePDF,
    filename: "Moonstone at Rosewood Brochure",
  },
  {
    endpoint: "patina-at-riversound",
    title: (
      <span>
        Patina <br />
        at RiverSound
      </span>
    ),
    subtitle: (
      <span>
        Single-Family Homes <br />
        in Napa, CA
      </span>
    ),
    sqft: "Approx. 2,340 – 3,173 Sq. Ft.",
    beds: "4 – 5",
    baths: "3 – 4.5",
    garage: "2-Bay",
    options: [],
    image: patinaExterior,
    pdf: patinaPDF,
    filename: "Patina at RiverSound Brochure",
  },
  {
    endpoint: "sundance-at-rosewood",
    title: (
      <span>
        Sundance <br />
        at Rosewood
      </span>
    ),
    subtitle: (
      <span>
        Duplex Homes <br />
        in Morgan Hill, CA
      </span>
    ),
    sqft: "Approx. 2,040 – 2,340 Sq. Ft.",
    beds: "3 – 5",
    baths: "2.5 – 3.5",
    garage: "2-Bay",
    options: ["Optional Bedroom 4 at Loft", "Optional Bedroom 5 at Loft"],
    image: sundanceExterior,
    pdf: sundancePDF,
    filename: "Sundance at Rosewood Brochure",
  },
]

export default routes
